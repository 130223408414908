import React, { useState, useCallback, useEffect } from 'react';
import Modal from '../common/Modal';
import OpportunityAIChat from './OpportunityAIChat';
import debounce from 'lodash/debounce';

function OpportunityModal({ show, opportunity, onClose, folders, onUpdate }) {
  const [activeTab, setActiveTab] = useState('info');
  const [aiError, setAiError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isCheckingUpdate, setIsCheckingUpdate] = useState(false);
  const [updateAlert, setUpdateAlert] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null);
  const [localNotes, setLocalNotes] = useState('');
  
  // Create a debounced save function
  const debouncedSave = useCallback(
    debounce(async (notes, oppId) => {
      if (!oppId) return;
      
      setIsSaving(true);
      setSaveStatus('saving');
      try {
        const response = await fetch('/api/update_notes', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            opportunityId: oppId,
            notes: notes
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to save notes');
        }

        setSaveStatus('saved');
        setTimeout(() => setSaveStatus(null), 2000); // Clear save status after 2 seconds
      } catch (error) {
        console.error('Error saving notes:', error);
        setSaveStatus('error');
      } finally {
        setIsSaving(false);
      }
    }, 1000),
    [] // Empty dependency array since we pass all needed values to the function
  );

  // Update local notes when opportunity changes
  useEffect(() => {
    if (opportunity) {
      setLocalNotes(opportunity.notes || '');
    }
  }, [opportunity]);

  const handleNotesChange = useCallback((e) => {
    const newNotes = e.target.value;
    setLocalNotes(newNotes);
    
    // Update parent component
    if (onUpdate && opportunity) {
      onUpdate({
        ...opportunity,
        notes: newNotes
      });
    }
    
    // Trigger debounced save
    debouncedSave(newNotes, opportunity?.id);
  }, [opportunity, onUpdate, debouncedSave]);

  const formatDate = useCallback((isoString) => {
    if (!isoString) return 'Not specified';
    return new Date(isoString).toLocaleString();
  }, []);

  if (!opportunity) return null;

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('opportunityId', opportunity.id);

    try {
      const response = await fetch('/api/upload_file', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const result = await response.json();
      if (result.success) {
        const updatedOpportunity = {
          ...opportunity,
          uploaded_files: [...(opportunity.uploaded_files || []), result.file]
        };
        
        if (onUpdate) {
          onUpdate(updatedOpportunity);
        }
      } else {
        throw new Error(result.error || 'Upload failed');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file. Please try again.');
    } finally {
      setIsUploading(false);
      event.target.value = '';
    }
  };

  const handleFileDownload = async (fileId, filename) => {
    try {
      const response = await fetch(`/api/download_file/${fileId}`, {
        credentials: 'include'
      });
      
      if (!response.ok) {
        if (response.status === 401) {
          window.location.href = '/login';
          return;
        }
        throw new Error('Download failed');
      }
      
      const data = await response.json();
      
      if (data.success && data.download_url) {
        const link = document.createElement('a');
        link.href = data.download_url;
        link.download = data.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        throw new Error(data.error || 'Failed to get download URL');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Failed to download file. Please try again.');
    }
  };

  const handleFileView = async (fileId, filename) => {
    try {
      const response = await fetch(`/api/view_file/${fileId}`, {
        credentials: 'include'
      });
      
      if (!response.ok) {
        if (response.status === 401) {
          window.location.href = '/login';
          return;
        }
        throw new Error('Failed to get file URL');
      }
      
      const data = await response.json();
      
      if (data.success && data.view_url) {
        const fileExt = filename.split('.').pop().toLowerCase();
        
        // Use Google Docs Viewer for Office documents
        if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(fileExt)) {
          const googleDocsUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(data.view_url)}&embedded=true`;
          window.open(googleDocsUrl, '_blank', 'noopener,noreferrer');
        } else if (data.is_viewable) {
          // For PDFs and images, open directly
          window.open(data.view_url, '_blank', 'noopener,noreferrer');
        } else {
          // For other files, download
          const link = document.createElement('a');
          link.href = data.view_url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          alert(`This file type (${fileExt}) cannot be previewed directly. Downloading instead.`);
        }
      } else {
        throw new Error(data.error || 'Failed to get file URL');
      }
    } catch (error) {
      console.error('Error viewing file:', error);
      alert('Failed to view file. Please try again.');
    }
  };

  const handleFileDelete = async (fileId) => {
    if (!window.confirm('Are you sure you want to delete this file?')) {
      return;
    }

    try {
      const response = await fetch(`/api/delete_file/${fileId}`, {
        method: 'DELETE',
        credentials: 'include'
      });

      if (!response.ok) {
        if (response.status === 401) {
          window.location.href = '/login';
          return;
        }
        throw new Error('Delete failed');
      }

      const data = await response.json();
      if (data.success) {
        const updatedOpportunity = {
          ...opportunity,
          uploaded_files: opportunity.uploaded_files.filter(file => file.id !== fileId)
        };
        
        if (onUpdate) {
          onUpdate(updatedOpportunity);
        }
      } else {
        throw new Error(data.error || 'Delete failed');
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      alert('Failed to delete file. Please try again.');
    }
  };

  const handleCheckUpdate = async () => {
    setIsCheckingUpdate(true);
    setUpdateAlert(null);
    try {
      const response = await fetch('/api/check_update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: opportunity.contract_url
        })
      });

      if (!response.ok) {
        throw new Error('Failed to check for updates');
      }

      const data = await response.json();
      
      if (data.hasUpdate) {
        setUpdateAlert({
          type: data.updateMessage.type || 'warning',
          message: data.updateMessage.text,
          url: data.updateMessage.url
        });
      } else {
        setUpdateAlert({
          type: 'info',
          message: 'No updates found for this opportunity.'
        });
      }

      // Update the local opportunity state if status changed
      if (data.status && data.status !== opportunity.status) {
        const updatedOpportunity = {
          ...opportunity,
          status: data.status
        };
        if (onUpdate) {
          onUpdate(updatedOpportunity);
        }
      }
    } catch (error) {
      console.error('Error checking for updates:', error);
      setUpdateAlert({
        type: 'error',
        message: 'Failed to check for updates. Please try again.'
      });
    } finally {
      setIsCheckingUpdate(false);
    }
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="space-y-6 max-w-4xl mx-auto">
        {/* Update Alert */}
        {updateAlert && (
          <div className={`rounded-md p-4 mb-4 ${
            updateAlert.type === 'warning' 
              ? 'bg-yellow-50 border border-yellow-200' 
              : updateAlert.type === 'error'
              ? 'bg-red-50 border border-red-200'
              : 'bg-blue-50 border border-blue-200'
          }`}>
            <div className="flex">
              <div className="flex-shrink-0">
                {updateAlert.type === 'warning' && (
                  <svg className="h-5 w-5 text-yellow-600" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                )}
              </div>
              <div className="ml-3">
                <p className={`text-sm ${
                  updateAlert.type === 'warning' 
                    ? 'text-yellow-700' 
                    : updateAlert.type === 'error'
                    ? 'text-red-700'
                    : 'text-blue-700'
                }`}>
                  {updateAlert.message}
                  {updateAlert.url && (
                    <a
                      href={updateAlert.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ml-2 text-blue-600 hover:text-blue-800 underline"
                    >
                      Click here to view the latest update and attachments
                    </a>
                  )}
                </p>
              </div>
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    onClick={() => setUpdateAlert(null)}
                    className={`inline-flex rounded-md p-1.5 ${
                      updateAlert.type === 'warning'
                        ? 'text-yellow-500 hover:bg-yellow-100'
                        : updateAlert.type === 'error'
                        ? 'text-red-500 hover:bg-red-100'
                        : 'text-blue-500 hover:bg-blue-100'
                    }`}
                  >
                    <span className="sr-only">Dismiss</span>
                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="border-b border-gray-100 pb-4 flex justify-between items-start">
          <div>
            <div className="flex items-center gap-3">
              <h2 className="text-2xl font-bold text-blue-800 leading-tight">
                {opportunity.title}
              </h2>
              <span className={`px-3 py-1 text-sm font-medium rounded-full inline-flex items-center ${
                opportunity.status === 'Active' 
                  ? 'bg-green-100 text-green-800'
                  : opportunity.status === 'Inactive'
                  ? 'bg-gray-100 text-gray-800'
                  : opportunity.status === 'Canceled'
                  ? 'bg-red-100 text-red-800'
                  : 'bg-blue-100 text-blue-800'
              }`}>
                <span className={`w-2 h-2 rounded-full mr-2 ${
                  opportunity.status === 'Active'
                    ? 'bg-green-500'
                    : opportunity.status === 'Inactive'
                    ? 'bg-gray-500'
                    : opportunity.status === 'Canceled'
                    ? 'bg-red-500'
                    : 'bg-blue-500'
                }`}></span>
                {opportunity.status || 'Unknown'}
              </span>
            </div>
            <p className="text-gray-500 text-sm mt-2">
              {opportunity.solicitation_number}
            </p>
          </div>
          <button
            onClick={handleCheckUpdate}
            disabled={isCheckingUpdate}
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
              isCheckingUpdate 
                ? 'bg-gray-100 text-gray-500 cursor-wait' 
                : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
            } flex items-center space-x-2`}
          >
            {isCheckingUpdate ? (
              <>
                <svg className="animate-spin h-4 w-4 mr-2" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                <span>Checking...</span>
              </>
            ) : (
              <>
                <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
                <span>Check Updates</span>
              </>
            )}
          </button>
        </div>
        
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {['info', 'notes', 'ai'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`${
                  activeTab === tab
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200 ease-in-out`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </nav>
        </div>

        {activeTab === 'info' && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="space-y-6">
                {[
                  { label: 'Solicitation Number', value: opportunity.solicitation_number },
                  { label: 'Response Deadline', value: formatDate(opportunity.response_deadline) },
                  { label: 'Posted Date', value: formatDate(opportunity.posted_date) },
                  { label: 'Set-Aside', value: opportunity.type_of_set_aside }
                ].map(({ label, value }) => (
                  <div key={label} className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                    <h3 className="text-sm font-medium text-gray-500">{label}</h3>
                    <p className="mt-1 text-gray-900 font-medium">{value || 'Not specified'}</p>
                  </div>
                ))}
              </div>
              
              <div className="space-y-6">
                {[
                  { label: 'Agency', value: opportunity.agency },
                  { label: 'NAICS Code', value: opportunity.naics_code },
                  { label: 'Place of Performance', value: opportunity.place_of_performance },
                  { label: 'Current Folder', value: opportunity.folder_names?.join(', ') }
                ].map(({ label, value }) => (
                  <div key={label} className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                    <h3 className="text-sm font-medium text-gray-500">{label}</h3>
                    <p className="mt-1 text-gray-900 font-medium">{value || 'Not specified'}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-8">
              <h3 className="text-sm font-medium text-gray-500 mb-3">Description</h3>
              <div className="prose max-w-none bg-white p-6 rounded-lg border border-gray-100 shadow-sm">
                {opportunity.description || 'No description available.'}
              </div>
            </div>
          </>
        )}

        {activeTab === 'notes' && (
          <div className="space-y-8">
            <div className="bg-white rounded-lg border border-gray-200 p-6">
              <div className="flex justify-between items-center mb-3">
                <h3 className="text-sm font-medium text-gray-500">Notes</h3>
                {saveStatus && (
                  <div className={`text-sm flex items-center ${
                    saveStatus === 'saving' ? 'text-blue-600' :
                    saveStatus === 'saved' ? 'text-green-600' :
                    'text-red-600'
                  }`}>
                    {saveStatus === 'saving' && (
                      <>
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Saving...
                      </>
                    )}
                    {saveStatus === 'saved' && (
                      <>
                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        Saved
                      </>
                    )}
                    {saveStatus === 'error' && (
                      <>
                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Error saving
                      </>
                    )}
                  </div>
                )}
              </div>
              <textarea
                className="w-full h-32 p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
                placeholder="Add your notes here..."
                value={localNotes}
                onChange={handleNotesChange}
              />
            </div>

            <div className="bg-white rounded-lg border border-gray-200 p-6">
              <h3 className="text-sm font-medium text-gray-500 mb-3">Uploaded Files</h3>
              <div className="space-y-3">
                {opportunity.uploaded_files?.length > 0 ? (
                  opportunity.uploaded_files.map((file) => (
                    <div key={file.id} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                      <span className="font-medium text-gray-700">{file.filename}</span>
                      <div className="flex space-x-3">
                        <button
                          onClick={() => handleFileView(file.id, file.filename)}
                          className="text-blue-600 hover:text-blue-800 transition-colors"
                        >
                          View
                        </button>
                        <button
                          onClick={() => handleFileDownload(file.id, file.filename)}
                          className="text-green-600 hover:text-green-800 transition-colors"
                        >
                          Download
                        </button>
                        <button 
                          onClick={() => handleFileDelete(file.id)}
                          className="text-red-600 hover:text-red-800 transition-colors"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500 italic">No files uploaded yet</p>
                )}
                
                <div className="mt-4">
                  <label
                    htmlFor="file-upload"
                    className={`inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg shadow-sm text-sm font-medium ${
                      isUploading 
                        ? 'bg-gray-100 text-gray-500 cursor-wait' 
                        : 'text-gray-700 bg-white hover:bg-gray-50 cursor-pointer'
                    } transition-colors`}
                  >
                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                    </svg>
                    {isUploading ? 'Uploading...' : 'Upload File'}
                  </label>
                  <input 
                    type="file" 
                    className="hidden" 
                    id="file-upload" 
                    onChange={handleFileUpload}
                    disabled={isUploading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'ai' && (
          <div className="space-y-4">
            {aiError && (
              <div className="text-red-600 bg-red-50 p-4 rounded-lg border border-red-100">
                {aiError}
              </div>
            )}
            <OpportunityAIChat 
              opportunity={opportunity} 
              onError={setAiError}
            />
          </div>
        )}

        <div className="flex justify-between space-x-4 mt-8 pt-6 border-t border-gray-100">
          <a
            href={opportunity.contract_url}
            target="_blank"
            rel="noopener noreferrer"
            className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transform hover:-translate-y-0.5 transition-all duration-300 shadow-sm hover:shadow-md inline-flex items-center"
          >
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
            View on SAM.gov
          </a>
          <button
            onClick={onClose}
            className="px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-all duration-300 inline-flex items-center"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default OpportunityModal; 